body {
  margin: 0;
  padding: 0;
  background-color: rgb(202, 190, 152);
  min-height: 100vh;
}
#site-title {
  font-weight: 800;
  font-size: 2em;
  color: #444;
  text-shadow: 0 1px 0 white;
}

.container {
  max-width: 80%;
  width: 600px !important;
}

#container {
  flex-grow: 1;
  width: 100%;
  /* background-color: pink; */
}

#suggestions {
  width: 100%;
  text-indent: 1rem;
  flex-grow: 0;
  /* background-color: lightblue; */
}

#suggestions li {
  font-weight: 500;
  padding: 0.1em 0;
  font-size: 1.1em;
  text-transform: capitalize;
}
